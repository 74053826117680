<template>
<div class="">
    <section class="height-100 masthead position-relative">
        <div class="masthead-overlay"></div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-6 text-center">
                    <b-navbar-brand to="/login" class="logo mb-base">
                        <img src="@/assets/images/pages/MyPals_Logo-01.svg" height="44" width="auto" class="mr-3" alt="MyPals" />
                        <!-- <img
                src="@/assets/images/pages/Coursepal-connect.svg"
                height="auto"
                width="64"
                class="mr-3"
                alt="Coursepal"
              />
              <div class="title-block">
                <h6 class="logo-title">Coursepal</h6>
                <div class="sub-title">Social Learning App</div>
              </div> -->
                    </b-navbar-brand>
                    <div class="card py-3 shadow-lg">
                        <div class="card-body">
                            <div class="mb-5">
                                <div class="mb-5">
                                    <h2 class="text-center">Verification email sent!</h2>
                                    <p>
                                        A verification link sent to your email address. Please click on the link to complete the process and log in using your email address and password.
                                    </p>
                                </div>
                                <form>
                                    <!--    <div class="form-group">
                      <input
                        id="verificationCode"
                        type="text"
                        class="form-control form-control-lg"
                        placeholder="Verification Code"
                        v-model="verificationCode"
                      />
                    </div>  -->
                                    <div class="form-group">
                                        <button ref="submitButton" class="btn btn-block btn-lg btn-primary" type="button" @click="verifyAccount">
                                            <!--  <b-spinner v-if="isLoading" /> -->
                                            <span> Log In </span>
                                        </button>
                                    </div>

                                    <!-- <div class="">
                      <p>Didn’t received the verification link? <br><a href="#">Send Again</a></p>
                    </div> -->

                                </form>
                                <!--      <form>
                    <div class="form-group">
                      <input
                        id="verificationCode"
                        type="text"
                        class="form-control form-control-lg"
                        placeholder="Verification Code"
                        v-model="verificationCode"
                      />
                    </div>
                    <div class="form-group">
                      <button
                        ref="submitButton"
                        class="btn btn-block btn-lg btn-primary"
                        type="button"
                        @click="verifyAccount"
                      >
                        <b-spinner v-if="isLoading" />
                        <span> Verify Code </span>
                      </button>
                    </div>
                  </form>   -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
export default {
    data() {
        return {
            verificationCode: '',
            isLoading: false
        }
    },
    methods: {
        async verifyAccount() {
            this.$store.dispatch('auth/confirmSignUp', {
                code: this.verificationCode,
                notify: this.$vs.notify
            }).then(() => {
                this.$router.push('/login').catch(() => {})
            })
        }
    }
}
</script>

<style scoped>
body {
    font-family: "Open Sans", sans-serif;
    color: #626262;
    font-size: 16px !important;
}

p {
    font-size: 16px !important;
    margin-bottom: 1rem !important;
}

span {
    font-size: 16px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #515365;
    /* color:#282828; */
    font-weight: 600;
}

h4,
h6 {
    margin-bottom: 0.5rem;
}

.masthead-text {
    color: #fff;
    font-size: 20px !important;
}

.navbar-dark .navbar-nav .nav-link {
    font-size: 16px !important;
}
</style>
